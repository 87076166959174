<template>
  <div class=" text-center" id="page-container">
    <div class="a4">
      <div class="x1">
        <div class="row">
          <div class="col-xs-1 col-md-1 col-sm-1"></div>
          <div class="col-xs-9 col-md-9 col-sm-9 tp">
            <p>
              الجدول الزمني لدوري الاكاديميات الخاصه لكره القدم النسخه
              {{ item.year }}
            </p>
            <p>
              تحت رعايه
              {{ item.undier }}
            </p>
            <p>
              يوم
              {{ arabicDay(item.day) }}
              الموفق
              {{ arabicDate(item.day) }}
            </p>
          </div>
          <div class="col-xs-2 col-md-2 col-sm-2">
            <img alt="logo" :src="logo" style="width: 74px;" />
          </div>
        </div>

        <table
          class="table table-striped table-bordered border-dark"
          data-show-print="true"
        >
          <thead>
            <tr class="table-s">
              <th scope="col">م</th>
              <th scope="col">مواليد</th>
              <th scope="col">المباريات</th>
              <th scope="col">ميعاد المباره</th>
              <th scope="col">لصالح</th>
              <th scope="col">لصالح</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itm, i) of list" :key="itm.id">
              <th scope="row">{{ i + 1 }}</th>
              <td>
                {{ itm.yearId.name }}
              </td>
              <td>
                <div class="row">
                  <div class="col-xs-5 col-md-5 col-sm-5" style="padding: 0;">
                    <img
                      :src="$baseUploadURL + itm.academieOneId.image"
                      class="t-image"
                    />
                    <p>{{ itm.academieOneId.userName }}</p>
                  </div>
                  <div class="col-xs-2 col-md-2 col-sm-2" style="padding: 0;">
                    X
                  </div>
                  <div class="col-xs-5 col-md-5 col-sm-5" style="padding: 0;">
                    <img
                      :src="$baseUploadURL + itm.academieTwoId.image"
                      class="t-image"
                    />
                    <p>{{ itm.academieTwoId.userName }}</p>
                  </div>
                </div>
              </td>
              <td>
                <div class="row">
                  <div class="col-xs-5 col-md-5 col-sm-5" style="padding: 0;">
                    {{ $TimeFormatz(itm.timeStart) }}
                  </div>
                  <div class="col-xs-2 col-md-2 col-sm-2" style="padding: 0;">
                    -
                  </div>
                  <div class="col-xs-5 col-md-5 col-sm-5" style="padding: 0;">
                    {{ $TimeFormatz(itm.timeEnd) }}
                  </div>
                </div>
              </td>
              <td>{{ itm.stadium }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      list: [],
      id: null,
      item: {
        year: null,
        undier: null,
        day: null,
      },
      logo: null,
    };
  },
  methods: {
    arabicDay(value) {
      if (value) {
        const duration = moment(String(value));
        const list = [
          'السبت',
          'الاحد',
          'الاثنين',
          'الثلاثاء',
          'الاربعاء',
          'الخميس',
          'الجمعه',
        ];
        return list[duration.format('d')];
      }
    },
    arabicDate(value) {
      if (value) {
        const duration = moment(String(value));

        return duration.locale('ar').format('DD MMMM yyyy');
      }
    },

    getData() {
      this.$http.get(`championship?ascorchitId=${this.id}`).then((response) => {
        this.list = response.data;
      });
    },
    getLeague() {
      this.$http
        .post(`league/search`, {
          select: ['image'],
          where: {
            status: true,
          },
          order: {
            id: 'DESC',
          },
        })
        .then((res) => {
          const list = res.data;
          if (list.length > 0) {
            this.logo = this.$baseUploadURL + list[0].image;
          } else {
            this.logo = '/images/logo.png';
          }
        });
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getData();
    this.getLeague();
    this.$http.get(`ascorchit/${this.id}`).then((res) => {
      this.item = res.data;
    });
  },
};
</script>

<style>
#page-container {
  background-color: #9e9e9e;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=');
  -webkit-transition: left 500ms;
  transition: left 500ms;
  padding: 13px 0;
}

.a4 {
  width: 918px;
  height: 1188px;
  margin: 0 auto;
  box-shadow: 1px 1px 3px 1px #333;
  border-collapse: separate;
  position: relative;
  background-color: white;
  overflow: hidden;
}
.x1 {
  margin: auto;
  margin-top: 33px;
  width: 650.13px;
}
.tp p {
  font-weight: bold;
}

.tp {
  padding: 0;
}

.table-s {
  background-color: #a5a5a5;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #343a40 !important;
}

.t-image {
  width: 65px;
}

@media print {
  @page {
    margin: 0;
  }

  #page-container {
    padding: 0 !important;
  }
  .a4 {
    width: 100% !important;
  }
  .border-dark {
    border-color: #343a40 !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #343a40 !important;
  }

  .table-s {
    background-color: #a5a5a5 !important;
  }
}
</style>
